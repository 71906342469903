<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar to="/ocorrencias" />
      </v-col>
    </v-row>

    <ocorrencias-campos
      :carregando-salvar="carregandoSalvar"
      :carregando-buscar="carregandoBuscar"
      :validacao-formulario-ocorrencia="validacaoFormularioOcorrencia"
      :ocorrencia="ocorrencia"
      @salvar="salvar"
    />
    </v-container>
</template>

<script>
  import ocorrenciasApi from '@/api/ocorrencias'

  export default {

    components: {
      OcorrenciasCampos: () => import('./OcorrenciasCampos'),
    },

    data () {
      return {
        carregandoSalvar: false,
        carregandoBuscar: false,
        validacaoFormularioOcorrencia: {},
        ocorrencia: {},
      }
    },

    mounted () {
      this.buscar()
    },

    methods: {
      async buscar () {
        try {
          this.carregandoBuscar = true

          const resposta = await ocorrenciasApi.buscar(this.$route.params.id)

          this.ocorrencia = resposta.data
        } catch (e) {
          this.$router.push('/ocorrencias', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
        } finally {
          this.carregandoBuscar = false
        }
      },

      async salvar (ocorrencia) {
        this.carregandoSalvar = true

        try {
          delete ocorrencia.data_ocorrencia_inicio
          delete ocorrencia.data_ocorrencia_fim

          await ocorrenciasApi.atualizar(ocorrencia.id, ocorrencia)

          this.$router.push('/ocorrencias', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_editar') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormularioOcorrencia = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoSalvar = false
        }
      },
    },

  }
</script>
